
function observeBars(event) {
	let barElements = document.querySelectorAll(".barBg");

	barElements.forEach((bar) => {
		let observer = new IntersectionObserver(handleIntersect, {
			root: null, // in relation to viewport
			rootMargin: "0px",
			threshold: 1.0,
		});
		observer.observe(bar);

		function handleIntersect(entries) {
			entries.forEach((entry) => {
				//console.log(entry);

				if (entry.isIntersecting) {
					entry.target.classList.add('shown');
				} else {
					//entry.target.classList.remove('shown');
				}
			});
		}
	});
}

document.addEventListener('DOMContentLoaded', observeBars);