import {throttle} from "throttle-debounce";

let logo = document.getElementById('logoOverlay');

if (logo) {
	logo.style.opacity = 1;

	function update() {
		let opacity = 100 - window.scrollY;

		if (opacity < 0) {
			opacity = 0;
		}

		opacity = opacity / 100;

		if (logo.style.opacity > opacity) {
			logo.style.opacity = opacity;
		}
	}

	document.addEventListener('DOMContentLoaded', update);
	window.addEventListener('scroll', throttle(8, update));
}