const imageBoxes = document.querySelectorAll(".imageBoxes");
const delays = ['delay-0', 'delay-75', 'delay-100', 'delay-150', 'delay-200', 'delay-300', 'delay-500', 'delay-700', 'delay-1000'];

function getRandomDelay() {
	return delays[Math.floor(Math.random() * delays.length)];
}

imageBoxes.forEach((box) => {
	const images= box.querySelectorAll('div.imageBox');

	images.forEach((image) => {
		image.classList.add('opacity-0');
	});
});


function observeImageBoxes(event) {
	imageBoxes.forEach((imageBox) => {
		let observer = new IntersectionObserver(handleIntersect, {
			root: null, // in relation to viewport
			rootMargin: "0px",
			threshold: 0.2,
		});
		observer.observe(imageBox);

		function handleIntersect(entries) {
			entries.forEach((entry) => {
				//console.log(entry);

				if (entry.isIntersecting) {
					const images= entry.target.querySelectorAll('div.imageBox');

					images.forEach((image) => {
						//console.log(image);

						image.classList.remove('opacity-0');
						image.classList.add('transition-opacity', 'duration-1000', 'ease-mine', 'opacity-1', getRandomDelay());
					});

				} else {
					//entry.target.classList.remove('shown');
				}
			});
		}
	});
}

window.addEventListener("load", observeImageBoxes);