import {throttle} from "throttle-debounce";

(function cloneMenu() {
	let menuClone = document.querySelector('ul.menu').cloneNode(true);
	menuClone.classList.remove('menu');

	let menuEcommerce = document.createElement("li");
	menuEcommerce.appendChild(document.querySelector('ul.menuEcommerce').cloneNode(true));

	document.getElementById('mobileMenu').appendChild(menuClone).appendChild(menuEcommerce);
})();

let header = document.getElementById('headerWrapper');

if (header.classList.contains('transparentTrigger')) {
	document.querySelector('a.menu-link').addEventListener('click', function(event) {
		event.preventDefault();

		if (header.classList.contains('transparent')) {
			header.classList.remove('transparent');

			if (window.innerWidth <= 1024) {
				document.querySelector('body').classList.toggle('panelOpen');
			}
		} else {
			document.querySelector('body').classList.toggle('panelOpen');
		}
	});

	function update() {
		if (window.scrollY > 10 && header.classList.contains('transparent')) {
			header.classList.remove('transparent');
		}

		if (window.scrollY === 0 && !header.classList.contains('transparent')) {
			header.classList.add('transparent');
		}
	}

	document.addEventListener('DOMContentLoaded', update);

	window.addEventListener('scroll', throttle(8, update));
	window.addEventListener('resize', throttle(16, update));
} else {
	document.querySelector('a.menu-link').addEventListener('click', function(event) {
		event.preventDefault();
		document.querySelector('body').classList.toggle('panelOpen');
	});
}
