import {CountUp} from "countup.js";

let countUps = [...document.getElementsByClassName('countUp')];

countUps.forEach(function(el) {
	let opts = {
		useGrouping: false,
		enableScrollSpy: true,
		scrollSpyOnce: true,
		scrollSpyDelay: 400,
		decimalPlaces: 0
	};

	if (el.dataset.decimal) {
		opts.decimalPlaces = el.dataset.decimal;
	}

	new CountUp(el, el.innerText, opts);
});
