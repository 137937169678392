import {throttle} from "throttle-debounce";
import Typed from "typed.js";

const line = document.getElementById('redLine');
const dot = document.getElementById('redDot');
const container = document.getElementById('redContainer');

if (container && dot && line) {
	let entriesElements = document.querySelectorAll(".timelineEntry");

	function isElementInViewport(el) {
		if (el instanceof Element) {
			const rect = el.getBoundingClientRect();
			return (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight));
		}

		return false;
	}

	function update() {
		let containerY = container.getBoundingClientRect().top + document.documentElement.scrollTop;

		let lastVisibleEntry = null;

		entriesElements.forEach((entry) => {
			const horizontalLine= entry.querySelector('.timeLineEntryLine');

			if (isElementInViewport(entry) || (horizontalLine && isElementInViewport(horizontalLine))) {
				lastVisibleEntry = entry;

				const left = entry.parentElement.querySelector('.timeLineEntryLeft');
				const right = entry.parentElement.querySelector('.timeLineEntryRight');
				left.classList.remove('lg:-left-full', '-right-full');
				left.classList.add('lg:left-0', 'right-0');

				setTimeout(() => {
					left.classList.remove('opacity-0');
					left.classList.add('opacity-100');
				}, 700);

				setTimeout(() => {
					right.classList.remove('-right-full');
					right.classList.add('right-0');
				}, 300);

				setTimeout(() => {
					right.classList.remove('opacity-0');
					right.classList.add('opacity-100');
				}, 600);
			}
		});

		if (lastVisibleEntry) {
			const horizontalLine= lastVisibleEntry.parentElement.querySelector('.timeLineEntryLine');

			if (horizontalLine) {
				let entryY = horizontalLine.getBoundingClientRect().top + document.documentElement.scrollTop - containerY;
				entryY = entryY - Math.round(dot.offsetHeight / 2);

				line.style.height = entryY + "px";
				dot.style.top = entryY + "px";
			}
		}
	}

	window.addEventListener('load', update);
	window.addEventListener('scroll', throttle(8, update));
	window.addEventListener('resize', throttle(16, update));
}