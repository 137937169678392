import Typed from "typed.js";

function observeQuotes(event) {
	let quoteElements = document.querySelectorAll(".quoteWithArrows cite");

	quoteElements.forEach((quote) => {
		const text = quote.innerText;
		quote.innerText = '';

		const typed = new Typed(quote, {
			strings: [text],
			typeSpeed: 50,
			showCursor: false
		});

		typed.stop();

		let observer = new IntersectionObserver(handleIntersect, {
			root: null, // in relation to viewport
			rootMargin: "0px",
			threshold: 1.0,
		});
		observer.observe(quote);

		function handleIntersect(entries, observer) {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					typed.start();
				}
			});
		}
	});
}

document.addEventListener('DOMContentLoaded', observeQuotes);